import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const anoAtual = new Date().getFullYear();

  return (
    <div className="relative bg-white w-full flex flex-col items-center py-10 z-50">
      <div className="flex flex-row items-start justify-center w-full gap-5">
        <h2 className="w-[40%] text-[40px] text-[#1060fe] font-bold whitespace-nowrap nirmala-font">
          <span className="text-[#90ff3c]">Soluções</span> <br />
          elétricas <br />
          sustentáveis
        </h2>
        <div className="h-[175px] w-[1px] bg-[#a6b3d1]"></div>
        <div className="w-[25%] flex flex-col gap-2">
          <h4 className="text-[18px] text-[#a6b3d1] roboto-flex-font">Menu</h4>
          <Link to="/aboutUs" state={{ scrollToTop: true }}>
            <h4 className="text-[18px] text-[#1060fe] hover:text-[#90ff3c] transition-colors roboto-flex-font">Sobre nós</h4>
          </Link>
          <Link to="/solutions" state={{ scrollToTop: true }}>
            <h4 className="text-[18px] text-[#1060fe] hover:text-[#90ff3c] transition-colors roboto-flex-font">Soluções</h4>
          </Link>
          <Link to="/" state={{ scrollToTop: true }}>
            <h4 className="text-[18px] text-[#1060fe] hover:text-[#90ff3c] transition-colors roboto-flex-font">Áreas de Investimento</h4>
          </Link>
          <Link to="/contact" state={{ scrollToTop: true }}>
            <h4 className="text-[18px] text-[#1060fe] hover:text-[#90ff3c] transition-colors roboto-flex-font">Contate-nos</h4>
          </Link>
        </div>
        <div className="h-[175px] w-[1px] bg-[#a6b3d1]"></div>
        <div className="w-[25%] flex flex-col gap-2">
          <h4 className="text-[18px] text-[#a6b3d1] roboto-flex-font">Contato</h4>
          <h4 className="text-[18px] text-[#1060fe] roboto-flex-font">BR 116, nº 30 - Bloco A - Rincão dos Ilhéus</h4>
          <h4 className="text-[18px] text-[#1060fe] roboto-flex-font">Estância Velha/RS, 93608-350</h4>
          <h4 className="text-[18px] text-[#1060fe] roboto-flex-font">+55-51 99845-1418</h4>
          <h4 className="text-[18px] text-[#1060fe] roboto-flex-font">comercial@voltmais.com.br</h4>
        </div>
      </div>
      <div className="flex flex-row items-start justify-between w-[96%] mt-60">
        <div className="flex flex-col gap-2 w-96">
          <a href="https://www.linkedin.com/company/voltmaisenergia/posts/?feedView=all" target="_blank" rel="noopener noreferrer" className="text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Linkedin
          </a>
          <a href="https://www.instagram.com/voltmais/" target="_blank" rel="noopener noreferrer" className="text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Instagram
          </a>
          <a href="https://www.facebook.com/VoltMAIS" target="_blank" rel="noopener noreferrer" className="text-[35px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors">
            Facebook
          </a>
        </div>
        <div className="w-full mt-auto pt-10">
          <div className="flex justify-end items-center px-4">
            <Link to="/privacyPolicy">
              <h4 className="text-[18px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors px-6">Política de Privacidade</h4>
            </Link>
            <Link to="/disclaimer">
              <h4 className="text-[18px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors px-6">Isenção de Responsabilidade</h4>
            </Link>
            <a className="text-[18px] text-[#1060fe] font-normal nirmala-font hover:text-[#90ff3c] transition-colors" href='https://prospectusnbn.com.br/bio' target='_blank' rel='noopener noreferrer'>
              Desenvolvido por PROSPECTUS NBN©️
            </a>&nbsp;- {anoAtual}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
