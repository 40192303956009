import React from 'react';
import BannerHome from '../../../src/assets/banner_home.webp';
import WhatsAppButton from '../../components/WhatsAppButton/WhatsAppButton'; // Certifique-se de criar este componente

const NotFound = () => {
  return (
    <div
      className="h-screen bg-cover bg-center flex flex-col justify-end items-center text-white px-10 lg:px-20"
      style={{
        backgroundImage: `linear-gradient(to top, rgba(0, 85, 254, 1), rgba(0, 85, 254, 0)), url(${BannerHome})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '90vh',
      }}
    >
      <div className="w-full flex flex-row justify-between items-end mb-28">
        <div className="w-[100%] flex items-end justify-start">
          <h1 className="text-[2vw] max-w-3xl leading-[0.9] text-left">
            <span className="text-[#4ed44f] nirmala-font">Página não encontrada!</span> <br />
            <span className="text-white nirmala-font">Estamos criando uma nova experiência para você!</span> <br />
            <span className="text-white nirmala-font">No momento você pode entrar em contato no whatsapp.</span>
          </h1>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
};

export default NotFound;
