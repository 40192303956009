import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BioLink from './pages/bioLink/BioLink';
import QrCode from './components/qrCode/QrCode';
import NotFound from '../src/pages/notFound/NotFound';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/bio" element={<BioLink />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
